export const A11yBar = () => import('../../src/components/A11yBar/A11yBar.vue' /* webpackChunkName: "components/a11y-bar" */).then(c => wrapFunctional(c.default || c))
export const AboutBusinessMetrics = () => import('../../src/components/AboutBusinessMetrics/AboutBusinessMetrics.vue' /* webpackChunkName: "components/about-business-metrics" */).then(c => wrapFunctional(c.default || c))
export const AccountAvatar = () => import('../../src/components/AccountAvatar/AccountAvatar.vue' /* webpackChunkName: "components/account-avatar" */).then(c => wrapFunctional(c.default || c))
export const A11yButton = () => import('../../src/components/A11yButton/A11yButton.vue' /* webpackChunkName: "components/a11y-button" */).then(c => wrapFunctional(c.default || c))
export const ActualTopicHeader = () => import('../../src/components/ActualTopicHeader/ActualTopicHeader.vue' /* webpackChunkName: "components/actual-topic-header" */).then(c => wrapFunctional(c.default || c))
export const ActualTopicHeaderMobile = () => import('../../src/components/ActualTopicHeaderMobile/ActualTopicHeaderMobile.vue' /* webpackChunkName: "components/actual-topic-header-mobile" */).then(c => wrapFunctional(c.default || c))
export const AdDisablingModal = () => import('../../src/components/AdDisablingModal/AdDisablingModal.vue' /* webpackChunkName: "components/ad-disabling-modal" */).then(c => wrapFunctional(c.default || c))
export const Adfox = () => import('../../src/components/Adfox/Adfox.vue' /* webpackChunkName: "components/adfox" */).then(c => wrapFunctional(c.default || c))
export const AdfoxAdvertiser = () => import('../../src/components/AdfoxAdvertiser/AdfoxAdvertiser.vue' /* webpackChunkName: "components/adfox-advertiser" */).then(c => wrapFunctional(c.default || c))
export const AdfoxDebug = () => import('../../src/components/AdfoxDebug/AdfoxDebug.vue' /* webpackChunkName: "components/adfox-debug" */).then(c => wrapFunctional(c.default || c))
export const AdfoxInformer = () => import('../../src/components/AdfoxInformer/AdfoxInformer.vue' /* webpackChunkName: "components/adfox-informer" */).then(c => wrapFunctional(c.default || c))
export const AdsForm = () => import('../../src/components/AdsForm/AdsForm.vue' /* webpackChunkName: "components/ads-form" */).then(c => wrapFunctional(c.default || c))
export const AdvancedSearch = () => import('../../src/components/AdvancedSearch/AdvancedSearch.vue' /* webpackChunkName: "components/advanced-search" */).then(c => wrapFunctional(c.default || c))
export const AdvancedSearchResults = () => import('../../src/components/AdvancedSearchResults/AdvancedSearchResults.vue' /* webpackChunkName: "components/advanced-search-results" */).then(c => wrapFunctional(c.default || c))
export const Alert = () => import('../../src/components/Alert/Alert.vue' /* webpackChunkName: "components/alert" */).then(c => wrapFunctional(c.default || c))
export const ArchiveSearch = () => import('../../src/components/ArchiveSearch/ArchiveSearch.vue' /* webpackChunkName: "components/archive-search" */).then(c => wrapFunctional(c.default || c))
export const ArchiveSearchResults = () => import('../../src/components/ArchiveSearchResults/ArchiveSearchResults.vue' /* webpackChunkName: "components/archive-search-results" */).then(c => wrapFunctional(c.default || c))
export const ArticleActions = () => import('../../src/components/ArticleActions/ArticleActions.vue' /* webpackChunkName: "components/article-actions" */).then(c => wrapFunctional(c.default || c))
export const ArticleAuthors = () => import('../../src/components/ArticleAuthors/ArticleAuthors.vue' /* webpackChunkName: "components/article-authors" */).then(c => wrapFunctional(c.default || c))
export const ArticleAuthorsList = () => import('../../src/components/ArticleAuthorsList/ArticleAuthorsList.vue' /* webpackChunkName: "components/article-authors-list" */).then(c => wrapFunctional(c.default || c))
export const ArticleBoxes = () => import('../../src/components/ArticleBoxes/ArticleBoxes.vue' /* webpackChunkName: "components/article-boxes" */).then(c => wrapFunctional(c.default || c))
export const ArticleGallery = () => import('../../src/components/ArticleGallery/ArticleGallery.vue' /* webpackChunkName: "components/article-gallery" */).then(c => wrapFunctional(c.default || c))
export const ArticleHeader = () => import('../../src/components/ArticleHeader/ArticleHeader.vue' /* webpackChunkName: "components/article-header" */).then(c => wrapFunctional(c.default || c))
export const ArticleHeaderAuthors = () => import('../../src/components/ArticleHeaderAuthors/ArticleHeaderAuthors.vue' /* webpackChunkName: "components/article-header-authors" */).then(c => wrapFunctional(c.default || c))
export const ArticleHeaderAuthorsList = () => import('../../src/components/ArticleHeaderAuthorsList/ArticleHeaderAuthorsList.vue' /* webpackChunkName: "components/article-header-authors-list" */).then(c => wrapFunctional(c.default || c))
export const ArticleHeaderEntries = () => import('../../src/components/ArticleHeaderEntries/ArticleHeaderEntries.vue' /* webpackChunkName: "components/article-header-entries" */).then(c => wrapFunctional(c.default || c))
export const ArticleHeaderImage = () => import('../../src/components/ArticleHeaderImage/ArticleHeaderImage.vue' /* webpackChunkName: "components/article-header-image" */).then(c => wrapFunctional(c.default || c))
export const ArticleList = () => import('../../src/components/ArticleList/ArticleList.vue' /* webpackChunkName: "components/article-list" */).then(c => wrapFunctional(c.default || c))
export const ArticleRating = () => import('../../src/components/ArticleRating/ArticleRating.vue' /* webpackChunkName: "components/article-rating" */).then(c => wrapFunctional(c.default || c))
export const ArticleRatingAbout = () => import('../../src/components/ArticleRatingAbout/ArticleRatingAbout.vue' /* webpackChunkName: "components/article-rating-about" */).then(c => wrapFunctional(c.default || c))
export const ArticleRatingGroups = () => import('../../src/components/ArticleRatingGroups/ArticleRatingGroups.vue' /* webpackChunkName: "components/article-rating-groups" */).then(c => wrapFunctional(c.default || c))
export const ArticleRatingWrapper = () => import('../../src/components/ArticleRatingWrapper/ArticleRatingWrapper.vue' /* webpackChunkName: "components/article-rating-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ArticleSlideAdfox = () => import('../../src/components/ArticleSlideAdfox/ArticleSlideAdfox.vue' /* webpackChunkName: "components/article-slide-adfox" */).then(c => wrapFunctional(c.default || c))
export const ArticleSlidePartner = () => import('../../src/components/ArticleSlidePartner/ArticleSlidePartner.vue' /* webpackChunkName: "components/article-slide-partner" */).then(c => wrapFunctional(c.default || c))
export const ArticleSlider = () => import('../../src/components/ArticleSlider/ArticleSlider.vue' /* webpackChunkName: "components/article-slider" */).then(c => wrapFunctional(c.default || c))
export const ArticleSportTable = () => import('../../src/components/ArticleSportTable/ArticleSportTable.vue' /* webpackChunkName: "components/article-sport-table" */).then(c => wrapFunctional(c.default || c))
export const ArticleTags = () => import('../../src/components/ArticleTags/ArticleTags.vue' /* webpackChunkName: "components/article-tags" */).then(c => wrapFunctional(c.default || c))
export const ArticleVideo = () => import('../../src/components/ArticleVideo/ArticleVideo.vue' /* webpackChunkName: "components/article-video" */).then(c => wrapFunctional(c.default || c))
export const ArticlesList = () => import('../../src/components/ArticlesList/ArticlesList.vue' /* webpackChunkName: "components/articles-list" */).then(c => wrapFunctional(c.default || c))
export const BaseButton = () => import('../../src/components/BaseButton/BaseButton.vue' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c))
export const BaseImage = () => import('../../src/components/BaseImage/BaseImage.vue' /* webpackChunkName: "components/base-image" */).then(c => wrapFunctional(c.default || c))
export const BaseSlider = () => import('../../src/components/BaseSlider/BaseSlider.vue' /* webpackChunkName: "components/base-slider" */).then(c => wrapFunctional(c.default || c))
export const Bookmark = () => import('../../src/components/Bookmark/Bookmark.vue' /* webpackChunkName: "components/bookmark" */).then(c => wrapFunctional(c.default || c))
export const BoxAdfox = () => import('../../src/components/BoxAdfox/BoxAdfox.vue' /* webpackChunkName: "components/box-adfox" */).then(c => wrapFunctional(c.default || c))
export const BoxArticle = () => import('../../src/components/BoxArticle/BoxArticle.vue' /* webpackChunkName: "components/box-article" */).then(c => wrapFunctional(c.default || c))
export const BoxAttachment = () => import('../../src/components/BoxAttachment/BoxAttachment.vue' /* webpackChunkName: "components/box-attachment" */).then(c => wrapFunctional(c.default || c))
export const BoxBlock = () => import('../../src/components/BoxBlock/BoxBlock.vue' /* webpackChunkName: "components/box-block" */).then(c => wrapFunctional(c.default || c))
export const BoxCard = () => import('../../src/components/BoxCard/BoxCard.vue' /* webpackChunkName: "components/box-card" */).then(c => wrapFunctional(c.default || c))
export const BoxCardsSlider = () => import('../../src/components/BoxCardsSlider/BoxCardsSlider.vue' /* webpackChunkName: "components/box-cards-slider" */).then(c => wrapFunctional(c.default || c))
export const BoxCustomBlock = () => import('../../src/components/BoxCustomBlock/BoxCustomBlock.vue' /* webpackChunkName: "components/box-custom-block" */).then(c => wrapFunctional(c.default || c))
export const BoxCustomEmbed = () => import('../../src/components/BoxCustomEmbed/BoxCustomEmbed.vue' /* webpackChunkName: "components/box-custom-embed" */).then(c => wrapFunctional(c.default || c))
export const BoxEmbed = () => import('../../src/components/BoxEmbed/BoxEmbed.vue' /* webpackChunkName: "components/box-embed" */).then(c => wrapFunctional(c.default || c))
export const BoxFranchiseList = () => import('../../src/components/BoxFranchiseList/BoxFranchiseList.vue' /* webpackChunkName: "components/box-franchise-list" */).then(c => wrapFunctional(c.default || c))
export const BoxGallery = () => import('../../src/components/BoxGallery/BoxGallery.vue' /* webpackChunkName: "components/box-gallery" */).then(c => wrapFunctional(c.default || c))
export const BoxHeading = () => import('../../src/components/BoxHeading/BoxHeading.vue' /* webpackChunkName: "components/box-heading" */).then(c => wrapFunctional(c.default || c))
export const BoxHeadingExtended = () => import('../../src/components/BoxHeadingExtended/BoxHeadingExtended.vue' /* webpackChunkName: "components/box-heading-extended" */).then(c => wrapFunctional(c.default || c))
export const BoxImage = () => import('../../src/components/BoxImage/BoxImage.vue' /* webpackChunkName: "components/box-image" */).then(c => wrapFunctional(c.default || c))
export const BoxList = () => import('../../src/components/BoxList/BoxList.vue' /* webpackChunkName: "components/box-list" */).then(c => wrapFunctional(c.default || c))
export const BoxListOfValues = () => import('../../src/components/BoxListOfValues/BoxListOfValues.vue' /* webpackChunkName: "components/box-list-of-values" */).then(c => wrapFunctional(c.default || c))
export const BoxListSlider = () => import('../../src/components/BoxListSlider/BoxListSlider.vue' /* webpackChunkName: "components/box-list-slider" */).then(c => wrapFunctional(c.default || c))
export const BoxModal = () => import('../../src/components/BoxModal/BoxModal.vue' /* webpackChunkName: "components/box-modal" */).then(c => wrapFunctional(c.default || c))
export const BoxNativeRoll = () => import('../../src/components/BoxNativeRoll/BoxNativeRoll.vue' /* webpackChunkName: "components/box-native-roll" */).then(c => wrapFunctional(c.default || c))
export const BoxParagraph = () => import('../../src/components/BoxParagraph/BoxParagraph.vue' /* webpackChunkName: "components/box-paragraph" */).then(c => wrapFunctional(c.default || c))
export const BoxPromo = () => import('../../src/components/BoxPromo/BoxPromo.vue' /* webpackChunkName: "components/box-promo" */).then(c => wrapFunctional(c.default || c))
export const BoxQuote = () => import('../../src/components/BoxQuote/BoxQuote.vue' /* webpackChunkName: "components/box-quote" */).then(c => wrapFunctional(c.default || c))
export const BoxRating = () => import('../../src/components/BoxRating/BoxRating.vue' /* webpackChunkName: "components/box-rating" */).then(c => wrapFunctional(c.default || c))
export const BoxTable = () => import('../../src/components/BoxTable/BoxTable.vue' /* webpackChunkName: "components/box-table" */).then(c => wrapFunctional(c.default || c))
export const BrandVoice = () => import('../../src/components/BrandVoice/BrandVoice.vue' /* webpackChunkName: "components/brand-voice" */).then(c => wrapFunctional(c.default || c))
export const BrandVoiceAbout = () => import('../../src/components/BrandVoiceAbout/BrandVoiceAbout.vue' /* webpackChunkName: "components/brand-voice-about" */).then(c => wrapFunctional(c.default || c))
export const BrandVoiceCard = () => import('../../src/components/BrandVoiceCard/BrandVoiceCard.vue' /* webpackChunkName: "components/brand-voice-card" */).then(c => wrapFunctional(c.default || c))
export const BrandVoiceGridMobile = () => import('../../src/components/BrandVoiceGridMobile/BrandVoiceGridMobile.vue' /* webpackChunkName: "components/brand-voice-grid-mobile" */).then(c => wrapFunctional(c.default || c))
export const BrandVoiceMobile = () => import('../../src/components/BrandVoiceMobile/BrandVoiceMobile.vue' /* webpackChunkName: "components/brand-voice-mobile" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../src/components/Breadcrumbs/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const Burger = () => import('../../src/components/Burger/Burger.vue' /* webpackChunkName: "components/burger" */).then(c => wrapFunctional(c.default || c))
export const CardActual = () => import('../../src/components/CardActual/CardActual.vue' /* webpackChunkName: "components/card-actual" */).then(c => wrapFunctional(c.default || c))
export const CardAdfox = () => import('../../src/components/CardAdfox/CardAdfox.vue' /* webpackChunkName: "components/card-adfox" */).then(c => wrapFunctional(c.default || c))
export const CardAdfoxMobile = () => import('../../src/components/CardAdfoxMobile/CardAdfoxMobile.vue' /* webpackChunkName: "components/card-adfox-mobile" */).then(c => wrapFunctional(c.default || c))
export const CardArchive = () => import('../../src/components/CardArchive/CardArchive.vue' /* webpackChunkName: "components/card-archive" */).then(c => wrapFunctional(c.default || c))
export const CardArticle = () => import('../../src/components/CardArticle/CardArticle.vue' /* webpackChunkName: "components/card-article" */).then(c => wrapFunctional(c.default || c))
export const CardArticleAdfox = () => import('../../src/components/CardArticleAdfox/CardArticleAdfox.vue' /* webpackChunkName: "components/card-article-adfox" */).then(c => wrapFunctional(c.default || c))
export const CardArticleMobile = () => import('../../src/components/CardArticleMobile/CardArticleMobile.vue' /* webpackChunkName: "components/card-article-mobile" */).then(c => wrapFunctional(c.default || c))
export const CardBackground = () => import('../../src/components/CardBackground/CardBackground.vue' /* webpackChunkName: "components/card-background" */).then(c => wrapFunctional(c.default || c))
export const CardBackgroundMobile = () => import('../../src/components/CardBackgroundMobile/CardBackgroundMobile.vue' /* webpackChunkName: "components/card-background-mobile" */).then(c => wrapFunctional(c.default || c))
export const CardBackgroundMobileAdfox = () => import('../../src/components/CardBackgroundMobileAdfox/CardBackgroundMobileAdfox.vue' /* webpackChunkName: "components/card-background-mobile-adfox" */).then(c => wrapFunctional(c.default || c))
export const CardEducationSubscription = () => import('../../src/components/CardEducationSubscription/CardEducationSubscription.vue' /* webpackChunkName: "components/card-education-subscription" */).then(c => wrapFunctional(c.default || c))
export const CardEventClub = () => import('../../src/components/CardEventClub/CardEventClub.vue' /* webpackChunkName: "components/card-event-club" */).then(c => wrapFunctional(c.default || c))
export const CardFaceClub = () => import('../../src/components/CardFaceClub/CardFaceClub.vue' /* webpackChunkName: "components/card-face-club" */).then(c => wrapFunctional(c.default || c))
export const CardFranchise = () => import('../../src/components/CardFranchise/CardFranchise.vue' /* webpackChunkName: "components/card-franchise" */).then(c => wrapFunctional(c.default || c))
export const CardGrid = () => import('../../src/components/CardGrid/CardGrid.vue' /* webpackChunkName: "components/card-grid" */).then(c => wrapFunctional(c.default || c))
export const CardHeader = () => import('../../src/components/CardHeader/CardHeader.vue' /* webpackChunkName: "components/card-header" */).then(c => wrapFunctional(c.default || c))
export const CardList = () => import('../../src/components/CardList/CardList.vue' /* webpackChunkName: "components/card-list" */).then(c => wrapFunctional(c.default || c))
export const CardListMobile = () => import('../../src/components/CardListMobile/CardListMobile.vue' /* webpackChunkName: "components/card-list-mobile" */).then(c => wrapFunctional(c.default || c))
export const CardListMobileItem = () => import('../../src/components/CardListMobileItem/CardListMobileItem.vue' /* webpackChunkName: "components/card-list-mobile-item" */).then(c => wrapFunctional(c.default || c))
export const CardListMobileItemAdfox = () => import('../../src/components/CardListMobileItemAdfox/CardListMobileItemAdfox.vue' /* webpackChunkName: "components/card-list-mobile-item-adfox" */).then(c => wrapFunctional(c.default || c))
export const CardMenu = () => import('../../src/components/CardMenu/CardMenu.vue' /* webpackChunkName: "components/card-menu" */).then(c => wrapFunctional(c.default || c))
export const CardNews = () => import('../../src/components/CardNews/CardNews.vue' /* webpackChunkName: "components/card-news" */).then(c => wrapFunctional(c.default || c))
export const CardOpinion = () => import('../../src/components/CardOpinion/CardOpinion.vue' /* webpackChunkName: "components/card-opinion" */).then(c => wrapFunctional(c.default || c))
export const CardPartner = () => import('../../src/components/CardPartner/CardPartner.vue' /* webpackChunkName: "components/card-partner" */).then(c => wrapFunctional(c.default || c))
export const CardSlider = () => import('../../src/components/CardSlider/CardSlider.vue' /* webpackChunkName: "components/card-slider" */).then(c => wrapFunctional(c.default || c))
export const CardSliderEducation = () => import('../../src/components/CardSliderEducation/CardSliderEducation.vue' /* webpackChunkName: "components/card-slider-education" */).then(c => wrapFunctional(c.default || c))
export const CardStory = () => import('../../src/components/CardStory/CardStory.vue' /* webpackChunkName: "components/card-story" */).then(c => wrapFunctional(c.default || c))
export const CardStoryAdfox = () => import('../../src/components/CardStoryAdfox/CardStoryAdfox.vue' /* webpackChunkName: "components/card-story-adfox" */).then(c => wrapFunctional(c.default || c))
export const CardStoryMobile = () => import('../../src/components/CardStoryMobile/CardStoryMobile.vue' /* webpackChunkName: "components/card-story-mobile" */).then(c => wrapFunctional(c.default || c))
export const CardTabsMobile = () => import('../../src/components/CardTabsMobile/CardTabsMobile.vue' /* webpackChunkName: "components/card-tabs-mobile" */).then(c => wrapFunctional(c.default || c))
export const ClubBiographyModal = () => import('../../src/components/ClubBiographyModal/ClubBiographyModal.vue' /* webpackChunkName: "components/club-biography-modal" */).then(c => wrapFunctional(c.default || c))
export const ClubEventArticleCard = () => import('../../src/components/ClubEventArticleCard/ClubEventArticleCard.vue' /* webpackChunkName: "components/club-event-article-card" */).then(c => wrapFunctional(c.default || c))
export const ClubEventsFilters = () => import('../../src/components/ClubEventsFilters/ClubEventsFilters.vue' /* webpackChunkName: "components/club-events-filters" */).then(c => wrapFunctional(c.default || c))
export const ClubFaceLabel = () => import('../../src/components/ClubFaceLabel/ClubFaceLabel.vue' /* webpackChunkName: "components/club-face-label" */).then(c => wrapFunctional(c.default || c))
export const ClubOfferModal = () => import('../../src/components/ClubOfferModal/ClubOfferModal.vue' /* webpackChunkName: "components/club-offer-modal" */).then(c => wrapFunctional(c.default || c))
export const ClubPartnerMobileCard = () => import('../../src/components/ClubPartnerMobileCard/ClubPartnerMobileCard.vue' /* webpackChunkName: "components/club-partner-mobile-card" */).then(c => wrapFunctional(c.default || c))
export const CommentClubDialog = () => import('../../src/components/CommentClubDialog/CommentClubDialog.vue' /* webpackChunkName: "components/comment-club-dialog" */).then(c => wrapFunctional(c.default || c))
export const CopyLink = () => import('../../src/components/CopyLink/CopyLink.vue' /* webpackChunkName: "components/copy-link" */).then(c => wrapFunctional(c.default || c))
export const DatePicker = () => import('../../src/components/DatePicker/DatePicker.vue' /* webpackChunkName: "components/date-picker" */).then(c => wrapFunctional(c.default || c))
export const Dialog = () => import('../../src/components/Dialog/Dialog.vue' /* webpackChunkName: "components/dialog" */).then(c => wrapFunctional(c.default || c))
export const DialogDelete = () => import('../../src/components/DialogDelete/DialogDelete.vue' /* webpackChunkName: "components/dialog-delete" */).then(c => wrapFunctional(c.default || c))
export const DialogDeleteAuthor = () => import('../../src/components/DialogDeleteAuthor/DialogDeleteAuthor.vue' /* webpackChunkName: "components/dialog-delete-author" */).then(c => wrapFunctional(c.default || c))
export const DialogRead = () => import('../../src/components/DialogRead/DialogRead.vue' /* webpackChunkName: "components/dialog-read" */).then(c => wrapFunctional(c.default || c))
export const DotsLoader = () => import('../../src/components/DotsLoader/DotsLoader.vue' /* webpackChunkName: "components/dots-loader" */).then(c => wrapFunctional(c.default || c))
export const Dropdown = () => import('../../src/components/Dropdown/Dropdown.vue' /* webpackChunkName: "components/dropdown" */).then(c => wrapFunctional(c.default || c))
export const EditLink = () => import('../../src/components/EditLink/EditLink.vue' /* webpackChunkName: "components/edit-link" */).then(c => wrapFunctional(c.default || c))
export const EducationSlidePodcast = () => import('../../src/components/EducationSlidePodcast/EducationSlidePodcast.vue' /* webpackChunkName: "components/education-slide-podcast" */).then(c => wrapFunctional(c.default || c))
export const EducationSubscription = () => import('../../src/components/EducationSubscription/EducationSubscription.vue' /* webpackChunkName: "components/education-subscription" */).then(c => wrapFunctional(c.default || c))
export const EmailSubscription = () => import('../../src/components/EmailSubscription/EmailSubscription.vue' /* webpackChunkName: "components/email-subscription" */).then(c => wrapFunctional(c.default || c))
export const EmailSubscriptionModal = () => import('../../src/components/EmailSubscriptionModal/EmailSubscriptionModal.vue' /* webpackChunkName: "components/email-subscription-modal" */).then(c => wrapFunctional(c.default || c))
export const Error = () => import('../../src/components/Error/Error.vue' /* webpackChunkName: "components/error" */).then(c => wrapFunctional(c.default || c))
export const FavoriteItem = () => import('../../src/components/FavoriteItem/FavoriteItem.vue' /* webpackChunkName: "components/favorite-item" */).then(c => wrapFunctional(c.default || c))
export const FeedSubscription = () => import('../../src/components/FeedSubscription/FeedSubscription.vue' /* webpackChunkName: "components/feed-subscription" */).then(c => wrapFunctional(c.default || c))
export const Filters = () => import('../../src/components/Filters/Filters.vue' /* webpackChunkName: "components/filters" */).then(c => wrapFunctional(c.default || c))
export const FiltersMobile = () => import('../../src/components/FiltersMobile/FiltersMobile.vue' /* webpackChunkName: "components/filters-mobile" */).then(c => wrapFunctional(c.default || c))
export const FormCheckbox = () => import('../../src/components/FormCheckbox/FormCheckbox.vue' /* webpackChunkName: "components/form-checkbox" */).then(c => wrapFunctional(c.default || c))
export const FormError = () => import('../../src/components/FormError/FormError.vue' /* webpackChunkName: "components/form-error" */).then(c => wrapFunctional(c.default || c))
export const FormInput = () => import('../../src/components/FormInput/FormInput.vue' /* webpackChunkName: "components/form-input" */).then(c => wrapFunctional(c.default || c))
export const FormRadioGroup = () => import('../../src/components/FormRadioGroup/FormRadioGroup.vue' /* webpackChunkName: "components/form-radio-group" */).then(c => wrapFunctional(c.default || c))
export const FormSelect = () => import('../../src/components/FormSelect/FormSelect.vue' /* webpackChunkName: "components/form-select" */).then(c => wrapFunctional(c.default || c))
export const FormTextarea = () => import('../../src/components/FormTextarea/FormTextarea.vue' /* webpackChunkName: "components/form-textarea" */).then(c => wrapFunctional(c.default || c))
export const FranchiseDetails = () => import('../../src/components/FranchiseDetails/FranchiseDetails.vue' /* webpackChunkName: "components/franchise-details" */).then(c => wrapFunctional(c.default || c))
export const FranchiseFilters = () => import('../../src/components/FranchiseFilters/FranchiseFilters.vue' /* webpackChunkName: "components/franchise-filters" */).then(c => wrapFunctional(c.default || c))
export const FranchiseFooter = () => import('../../src/components/FranchiseFooter/FranchiseFooter.vue' /* webpackChunkName: "components/franchise-footer" */).then(c => wrapFunctional(c.default || c))
export const FranchiseInfoCard = () => import('../../src/components/FranchiseInfoCard/FranchiseInfoCard.vue' /* webpackChunkName: "components/franchise-info-card" */).then(c => wrapFunctional(c.default || c))
export const FranchiseLabel = () => import('../../src/components/FranchiseLabel/FranchiseLabel.vue' /* webpackChunkName: "components/franchise-label" */).then(c => wrapFunctional(c.default || c))
export const FranchiseOfferModal = () => import('../../src/components/FranchiseOfferModal/FranchiseOfferModal.vue' /* webpackChunkName: "components/franchise-offer-modal" */).then(c => wrapFunctional(c.default || c))
export const FranchiseRequestModal = () => import('../../src/components/FranchiseRequestModal/FranchiseRequestModal.vue' /* webpackChunkName: "components/franchise-request-modal" */).then(c => wrapFunctional(c.default || c))
export const FranchiseSlider = () => import('../../src/components/FranchiseSlider/FranchiseSlider.vue' /* webpackChunkName: "components/franchise-slider" */).then(c => wrapFunctional(c.default || c))
export const FranchiseSliderSimilar = () => import('../../src/components/FranchiseSliderSimilar/FranchiseSliderSimilar.vue' /* webpackChunkName: "components/franchise-slider-similar" */).then(c => wrapFunctional(c.default || c))
export const FranchiseTariffCard = () => import('../../src/components/FranchiseTariffCard/FranchiseTariffCard.vue' /* webpackChunkName: "components/franchise-tariff-card" */).then(c => wrapFunctional(c.default || c))
export const FreshMagazine = () => import('../../src/components/FreshMagazine/FreshMagazine.vue' /* webpackChunkName: "components/fresh-magazine" */).then(c => wrapFunctional(c.default || c))
export const FullscreenAdfox = () => import('../../src/components/FullscreenAdfox/FullscreenAdfox.vue' /* webpackChunkName: "components/fullscreen-adfox" */).then(c => wrapFunctional(c.default || c))
export const Gallery = () => import('../../src/components/Gallery/Gallery.vue' /* webpackChunkName: "components/gallery" */).then(c => wrapFunctional(c.default || c))
export const GalleryHero = () => import('../../src/components/GalleryHero/GalleryHero.vue' /* webpackChunkName: "components/gallery-hero" */).then(c => wrapFunctional(c.default || c))
export const GalleryModal = () => import('../../src/components/GalleryModal/GalleryModal.vue' /* webpackChunkName: "components/gallery-modal" */).then(c => wrapFunctional(c.default || c))
export const GallerySlider = () => import('../../src/components/GallerySlider/GallerySlider.vue' /* webpackChunkName: "components/gallery-slider" */).then(c => wrapFunctional(c.default || c))
export const Grid = () => import('../../src/components/Grid/Grid.vue' /* webpackChunkName: "components/grid" */).then(c => wrapFunctional(c.default || c))
export const Headline = () => import('../../src/components/Headline/Headline.vue' /* webpackChunkName: "components/headline" */).then(c => wrapFunctional(c.default || c))
export const IconButton = () => import('../../src/components/IconButton/IconButton.vue' /* webpackChunkName: "components/icon-button" */).then(c => wrapFunctional(c.default || c))
export const ImageAvatar = () => import('../../src/components/ImageAvatar/ImageAvatar.vue' /* webpackChunkName: "components/image-avatar" */).then(c => wrapFunctional(c.default || c))
export const InfoDialog = () => import('../../src/components/InfoDialog/InfoDialog.vue' /* webpackChunkName: "components/info-dialog" */).then(c => wrapFunctional(c.default || c))
export const InfoModal = () => import('../../src/components/InfoModal/InfoModal.vue' /* webpackChunkName: "components/info-modal" */).then(c => wrapFunctional(c.default || c))
export const Informer = () => import('../../src/components/Informer/Informer.vue' /* webpackChunkName: "components/informer" */).then(c => wrapFunctional(c.default || c))
export const Infox = () => import('../../src/components/Infox/Infox.vue' /* webpackChunkName: "components/infox" */).then(c => wrapFunctional(c.default || c))
export const IntersectionObserver = () => import('../../src/components/IntersectionObserver/IntersectionObserver.vue' /* webpackChunkName: "components/intersection-observer" */).then(c => wrapFunctional(c.default || c))
export const LinesLoader = () => import('../../src/components/LinesLoader/LinesLoader.vue' /* webpackChunkName: "components/lines-loader" */).then(c => wrapFunctional(c.default || c))
export const LinkBlock = () => import('../../src/components/LinkBlock/LinkBlock.vue' /* webpackChunkName: "components/link-block" */).then(c => wrapFunctional(c.default || c))
export const ListSlider = () => import('../../src/components/ListSlider/ListSlider.vue' /* webpackChunkName: "components/list-slider" */).then(c => wrapFunctional(c.default || c))
export const ListSliderSlide = () => import('../../src/components/ListSliderSlide/ListSliderSlide.vue' /* webpackChunkName: "components/list-slider-slide" */).then(c => wrapFunctional(c.default || c))
export const Menu = () => import('../../src/components/Menu/Menu.vue' /* webpackChunkName: "components/menu" */).then(c => wrapFunctional(c.default || c))
export const MicroLoader = () => import('../../src/components/MicroLoader/MicroLoader.vue' /* webpackChunkName: "components/micro-loader" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../src/components/Modal/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const NativeRoll = () => import('../../src/components/NativeRoll/NativeRoll.vue' /* webpackChunkName: "components/native-roll" */).then(c => wrapFunctional(c.default || c))
export const NewsList = () => import('../../src/components/NewsList/NewsList.vue' /* webpackChunkName: "components/news-list" */).then(c => wrapFunctional(c.default || c))
export const NewsletterSubscription = () => import('../../src/components/NewsletterSubscription/NewsletterSubscription.vue' /* webpackChunkName: "components/newsletter-subscription" */).then(c => wrapFunctional(c.default || c))
export const Page = () => import('../../src/components/Page/Page.vue' /* webpackChunkName: "components/page" */).then(c => wrapFunctional(c.default || c))
export const PageHeaderAdfox = () => import('../../src/components/PageHeaderAdfox/PageHeaderAdfox.vue' /* webpackChunkName: "components/page-header-adfox" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../src/components/Pagination/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const PaymentAutoRenewal = () => import('../../src/components/PaymentAutoRenewal/PaymentAutoRenewal.vue' /* webpackChunkName: "components/payment-auto-renewal" */).then(c => wrapFunctional(c.default || c))
export const PaymentForm = () => import('../../src/components/PaymentForm/PaymentForm.vue' /* webpackChunkName: "components/payment-form" */).then(c => wrapFunctional(c.default || c))
export const PaymentModal = () => import('../../src/components/PaymentModal/PaymentModal.vue' /* webpackChunkName: "components/payment-modal" */).then(c => wrapFunctional(c.default || c))
export const PaymentModalDone = () => import('../../src/components/PaymentModalDone/PaymentModalDone.vue' /* webpackChunkName: "components/payment-modal-done" */).then(c => wrapFunctional(c.default || c))
export const Person = () => import('../../src/components/Person/Person.vue' /* webpackChunkName: "components/person" */).then(c => wrapFunctional(c.default || c))
export const PodcastCard = () => import('../../src/components/PodcastCard/PodcastCard.vue' /* webpackChunkName: "components/podcast-card" */).then(c => wrapFunctional(c.default || c))
export const PodcastHeader = () => import('../../src/components/PodcastHeader/PodcastHeader.vue' /* webpackChunkName: "components/podcast-header" */).then(c => wrapFunctional(c.default || c))
export const PodcastItem = () => import('../../src/components/PodcastItem/PodcastItem.vue' /* webpackChunkName: "components/podcast-item" */).then(c => wrapFunctional(c.default || c))
export const PodcastItemHeader = () => import('../../src/components/PodcastItemHeader/PodcastItemHeader.vue' /* webpackChunkName: "components/podcast-item-header" */).then(c => wrapFunctional(c.default || c))
export const PodcastPartner = () => import('../../src/components/PodcastPartner/PodcastPartner.vue' /* webpackChunkName: "components/podcast-partner" */).then(c => wrapFunctional(c.default || c))
export const PodcastPlayer = () => import('../../src/components/PodcastPlayer/PodcastPlayer.vue' /* webpackChunkName: "components/podcast-player" */).then(c => wrapFunctional(c.default || c))
export const PodcastPlayerMobile = () => import('../../src/components/PodcastPlayerMobile/PodcastPlayerMobile.vue' /* webpackChunkName: "components/podcast-player-mobile" */).then(c => wrapFunctional(c.default || c))
export const PodcastTimecodes = () => import('../../src/components/PodcastTimecodes/PodcastTimecodes.vue' /* webpackChunkName: "components/podcast-timecodes" */).then(c => wrapFunctional(c.default || c))
export const PodcastsSlider = () => import('../../src/components/PodcastsSlider/PodcastsSlider.vue' /* webpackChunkName: "components/podcasts-slider" */).then(c => wrapFunctional(c.default || c))
export const PodcastsSliderMobile = () => import('../../src/components/PodcastsSliderMobile/PodcastsSliderMobile.vue' /* webpackChunkName: "components/podcasts-slider-mobile" */).then(c => wrapFunctional(c.default || c))
export const PricingFeatures = () => import('../../src/components/PricingFeatures/PricingFeatures.vue' /* webpackChunkName: "components/pricing-features" */).then(c => wrapFunctional(c.default || c))
export const PricingPlan = () => import('../../src/components/PricingPlan/PricingPlan.vue' /* webpackChunkName: "components/pricing-plan" */).then(c => wrapFunctional(c.default || c))
export const PricingSlider = () => import('../../src/components/PricingSlider/PricingSlider.vue' /* webpackChunkName: "components/pricing-slider" */).then(c => wrapFunctional(c.default || c))
export const ProfileAuthor = () => import('../../src/components/ProfileAuthor/ProfileAuthor.vue' /* webpackChunkName: "components/profile-author" */).then(c => wrapFunctional(c.default || c))
export const ProfileCard = () => import('../../src/components/ProfileCard/ProfileCard.vue' /* webpackChunkName: "components/profile-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileClubFaceCard = () => import('../../src/components/ProfileClubFaceCard/ProfileClubFaceCard.vue' /* webpackChunkName: "components/profile-club-face-card" */).then(c => wrapFunctional(c.default || c))
export const ProfilePerson = () => import('../../src/components/ProfilePerson/ProfilePerson.vue' /* webpackChunkName: "components/profile-person" */).then(c => wrapFunctional(c.default || c))
export const ProfilePersonChart = () => import('../../src/components/ProfilePersonChart/ProfilePersonChart.vue' /* webpackChunkName: "components/profile-person-chart" */).then(c => wrapFunctional(c.default || c))
export const ProfilePersonChartMobile = () => import('../../src/components/ProfilePersonChartMobile/ProfilePersonChartMobile.vue' /* webpackChunkName: "components/profile-person-chart-mobile" */).then(c => wrapFunctional(c.default || c))
export const ProfilePersonMobile = () => import('../../src/components/ProfilePersonMobile/ProfilePersonMobile.vue' /* webpackChunkName: "components/profile-person-mobile" */).then(c => wrapFunctional(c.default || c))
export const ProgressRing = () => import('../../src/components/ProgressRing/ProgressRing.vue' /* webpackChunkName: "components/progress-ring" */).then(c => wrapFunctional(c.default || c))
export const PromoCTA = () => import('../../src/components/PromoCTA/PromoCTA.vue' /* webpackChunkName: "components/promo-c-t-a" */).then(c => wrapFunctional(c.default || c))
export const PromocodeModal = () => import('../../src/components/PromocodeModal/PromocodeModal.vue' /* webpackChunkName: "components/promocode-modal" */).then(c => wrapFunctional(c.default || c))
export const RatingBody = () => import('../../src/components/RatingBody/RatingBody.vue' /* webpackChunkName: "components/rating-body" */).then(c => wrapFunctional(c.default || c))
export const RatingCard = () => import('../../src/components/RatingCard/RatingCard.vue' /* webpackChunkName: "components/rating-card" */).then(c => wrapFunctional(c.default || c))
export const RatingHead = () => import('../../src/components/RatingHead/RatingHead.vue' /* webpackChunkName: "components/rating-head" */).then(c => wrapFunctional(c.default || c))
export const RatingHeadSport = () => import('../../src/components/RatingHeadSport/RatingHeadSport.vue' /* webpackChunkName: "components/rating-head-sport" */).then(c => wrapFunctional(c.default || c))
export const RatingItem = () => import('../../src/components/RatingItem/RatingItem.vue' /* webpackChunkName: "components/rating-item" */).then(c => wrapFunctional(c.default || c))
export const RatingItemSport = () => import('../../src/components/RatingItemSport/RatingItemSport.vue' /* webpackChunkName: "components/rating-item-sport" */).then(c => wrapFunctional(c.default || c))
export const RatingItemTooltip = () => import('../../src/components/RatingItemTooltip/RatingItemTooltip.vue' /* webpackChunkName: "components/rating-item-tooltip" */).then(c => wrapFunctional(c.default || c))
export const RatingTooltip = () => import('../../src/components/RatingTooltip/RatingTooltip.vue' /* webpackChunkName: "components/rating-tooltip" */).then(c => wrapFunctional(c.default || c))
export const ReadMore = () => import('../../src/components/ReadMore/ReadMore.vue' /* webpackChunkName: "components/read-more" */).then(c => wrapFunctional(c.default || c))
export const RecommendationBanner = () => import('../../src/components/RecommendationBanner/RecommendationBanner.vue' /* webpackChunkName: "components/recommendation-banner" */).then(c => wrapFunctional(c.default || c))
export const RecommendationBannerMobile = () => import('../../src/components/RecommendationBannerMobile/RecommendationBannerMobile.vue' /* webpackChunkName: "components/recommendation-banner-mobile" */).then(c => wrapFunctional(c.default || c))
export const RestartButton = () => import('../../src/components/RestartButton/RestartButton.vue' /* webpackChunkName: "components/restart-button" */).then(c => wrapFunctional(c.default || c))
export const RotatingBanner = () => import('../../src/components/RotatingBanner/RotatingBanner.vue' /* webpackChunkName: "components/rotating-banner" */).then(c => wrapFunctional(c.default || c))
export const RotatingBannerMobile = () => import('../../src/components/RotatingBannerMobile/RotatingBannerMobile.vue' /* webpackChunkName: "components/rotating-banner-mobile" */).then(c => wrapFunctional(c.default || c))
export const SberSpeech = () => import('../../src/components/SberSpeech/SberSpeech.vue' /* webpackChunkName: "components/sber-speech" */).then(c => wrapFunctional(c.default || c))
export const SberSpeechMobile = () => import('../../src/components/SberSpeechMobile/SberSpeechMobile.vue' /* webpackChunkName: "components/sber-speech-mobile" */).then(c => wrapFunctional(c.default || c))
export const ScannerApplicationModal = () => import('../../src/components/ScannerApplicationModal/ScannerApplicationModal.vue' /* webpackChunkName: "components/scanner-application-modal" */).then(c => wrapFunctional(c.default || c))
export const ScannerInfo = () => import('../../src/components/ScannerInfo/ScannerInfo.vue' /* webpackChunkName: "components/scanner-info" */).then(c => wrapFunctional(c.default || c))
export const ScannerResults = () => import('../../src/components/ScannerResults/ScannerResults.vue' /* webpackChunkName: "components/scanner-results" */).then(c => wrapFunctional(c.default || c))
export const ScannerSearch = () => import('../../src/components/ScannerSearch/ScannerSearch.vue' /* webpackChunkName: "components/scanner-search" */).then(c => wrapFunctional(c.default || c))
export const SearchLoader = () => import('../../src/components/SearchLoader/SearchLoader.vue' /* webpackChunkName: "components/search-loader" */).then(c => wrapFunctional(c.default || c))
export const SearchResultsItem = () => import('../../src/components/SearchResultsItem/SearchResultsItem.vue' /* webpackChunkName: "components/search-results-item" */).then(c => wrapFunctional(c.default || c))
export const SearchTopic = () => import('../../src/components/SearchTopic/SearchTopic.vue' /* webpackChunkName: "components/search-topic" */).then(c => wrapFunctional(c.default || c))
export const ShareButton = () => import('../../src/components/ShareButton/ShareButton.vue' /* webpackChunkName: "components/share-button" */).then(c => wrapFunctional(c.default || c))
export const Sign = () => import('../../src/components/Sign/Sign.vue' /* webpackChunkName: "components/sign" */).then(c => wrapFunctional(c.default || c))
export const SignChangePassword = () => import('../../src/components/SignChangePassword/SignChangePassword.vue' /* webpackChunkName: "components/sign-change-password" */).then(c => wrapFunctional(c.default || c))
export const SignIn = () => import('../../src/components/SignIn/SignIn.vue' /* webpackChunkName: "components/sign-in" */).then(c => wrapFunctional(c.default || c))
export const SignRecovery = () => import('../../src/components/SignRecovery/SignRecovery.vue' /* webpackChunkName: "components/sign-recovery" */).then(c => wrapFunctional(c.default || c))
export const SignRecoveryDone = () => import('../../src/components/SignRecoveryDone/SignRecoveryDone.vue' /* webpackChunkName: "components/sign-recovery-done" */).then(c => wrapFunctional(c.default || c))
export const SignSocials = () => import('../../src/components/SignSocials/SignSocials.vue' /* webpackChunkName: "components/sign-socials" */).then(c => wrapFunctional(c.default || c))
export const SignUp = () => import('../../src/components/SignUp/SignUp.vue' /* webpackChunkName: "components/sign-up" */).then(c => wrapFunctional(c.default || c))
export const SignUpDone = () => import('../../src/components/SignUpDone/SignUpDone.vue' /* webpackChunkName: "components/sign-up-done" */).then(c => wrapFunctional(c.default || c))
export const Slider = () => import('../../src/components/Slider/Slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))
export const SliderMobile = () => import('../../src/components/SliderMobile/SliderMobile.vue' /* webpackChunkName: "components/slider-mobile" */).then(c => wrapFunctional(c.default || c))
export const SliderMobileSlide = () => import('../../src/components/SliderMobileSlide/SliderMobileSlide.vue' /* webpackChunkName: "components/slider-mobile-slide" */).then(c => wrapFunctional(c.default || c))
export const SliderMobileSlideBrandvoice = () => import('../../src/components/SliderMobileSlideBrandvoice/SliderMobileSlideBrandvoice.vue' /* webpackChunkName: "components/slider-mobile-slide-brandvoice" */).then(c => wrapFunctional(c.default || c))
export const SliderMobileSlideCardVideo = () => import('../../src/components/SliderMobileSlideCardVideo/SliderMobileSlideCardVideo.vue' /* webpackChunkName: "components/slider-mobile-slide-card-video" */).then(c => wrapFunctional(c.default || c))
export const SliderMobileSlideOpinion = () => import('../../src/components/SliderMobileSlideOpinion/SliderMobileSlideOpinion.vue' /* webpackChunkName: "components/slider-mobile-slide-opinion" */).then(c => wrapFunctional(c.default || c))
export const SliderMobileSlidePodcast = () => import('../../src/components/SliderMobileSlidePodcast/SliderMobileSlidePodcast.vue' /* webpackChunkName: "components/slider-mobile-slide-podcast" */).then(c => wrapFunctional(c.default || c))
export const SliderMobileSlideVideo = () => import('../../src/components/SliderMobileSlideVideo/SliderMobileSlideVideo.vue' /* webpackChunkName: "components/slider-mobile-slide-video" */).then(c => wrapFunctional(c.default || c))
export const SliderSlide = () => import('../../src/components/SliderSlide/SliderSlide.vue' /* webpackChunkName: "components/slider-slide" */).then(c => wrapFunctional(c.default || c))
export const SliderSlidePartner = () => import('../../src/components/SliderSlidePartner/SliderSlidePartner.vue' /* webpackChunkName: "components/slider-slide-partner" */).then(c => wrapFunctional(c.default || c))
export const SliderSlidePartnerAdfox = () => import('../../src/components/SliderSlidePartnerAdfox/SliderSlidePartnerAdfox.vue' /* webpackChunkName: "components/slider-slide-partner-adfox" */).then(c => wrapFunctional(c.default || c))
export const SliderSlidePodcast = () => import('../../src/components/SliderSlidePodcast/SliderSlidePodcast.vue' /* webpackChunkName: "components/slider-slide-podcast" */).then(c => wrapFunctional(c.default || c))
export const SliderSlideSpecial = () => import('../../src/components/SliderSlideSpecial/SliderSlideSpecial.vue' /* webpackChunkName: "components/slider-slide-special" */).then(c => wrapFunctional(c.default || c))
export const SliderSlideVideo = () => import('../../src/components/SliderSlideVideo/SliderSlideVideo.vue' /* webpackChunkName: "components/slider-slide-video" */).then(c => wrapFunctional(c.default || c))
export const Smi2 = () => import('../../src/components/Smi2/Smi2.vue' /* webpackChunkName: "components/smi2" */).then(c => wrapFunctional(c.default || c))
export const Smi2Mobile = () => import('../../src/components/Smi2Mobile/Smi2Mobile.vue' /* webpackChunkName: "components/smi2-mobile" */).then(c => wrapFunctional(c.default || c))
export const Smi2Paid = () => import('../../src/components/Smi2Paid/Smi2Paid.vue' /* webpackChunkName: "components/smi2-paid" */).then(c => wrapFunctional(c.default || c))
export const SocialLinks = () => import('../../src/components/SocialLinks/SocialLinks.vue' /* webpackChunkName: "components/social-links" */).then(c => wrapFunctional(c.default || c))
export const SortArrows = () => import('../../src/components/SortArrows/SortArrows.vue' /* webpackChunkName: "components/sort-arrows" */).then(c => wrapFunctional(c.default || c))
export const Sparrow = () => import('../../src/components/Sparrow/Sparrow.vue' /* webpackChunkName: "components/sparrow" */).then(c => wrapFunctional(c.default || c))
export const SparrowMobile = () => import('../../src/components/SparrowMobile/SparrowMobile.vue' /* webpackChunkName: "components/sparrow-mobile" */).then(c => wrapFunctional(c.default || c))
export const SpeechButton = () => import('../../src/components/SpeechButton/SpeechButton.vue' /* webpackChunkName: "components/speech-button" */).then(c => wrapFunctional(c.default || c))
export const SpeechPlayer = () => import('../../src/components/SpeechPlayer/SpeechPlayer.vue' /* webpackChunkName: "components/speech-player" */).then(c => wrapFunctional(c.default || c))
export const StopReadingButton = () => import('../../src/components/StopReadingButton/StopReadingButton.vue' /* webpackChunkName: "components/stop-reading-button" */).then(c => wrapFunctional(c.default || c))
export const Tab = () => import('../../src/components/Tab/Tab.vue' /* webpackChunkName: "components/tab" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../src/components/Tabs/Tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const TabsPlain = () => import('../../src/components/TabsPlain/TabsPlain.vue' /* webpackChunkName: "components/tabs-plain" */).then(c => wrapFunctional(c.default || c))
export const TheArticle = () => import('../../src/components/TheArticle/TheArticle.vue' /* webpackChunkName: "components/the-article" */).then(c => wrapFunctional(c.default || c))
export const TheCarousel = () => import('../../src/components/TheCarousel/TheCarousel.vue' /* webpackChunkName: "components/the-carousel" */).then(c => wrapFunctional(c.default || c))
export const TheCarouselMobile = () => import('../../src/components/TheCarouselMobile/TheCarouselMobile.vue' /* webpackChunkName: "components/the-carousel-mobile" */).then(c => wrapFunctional(c.default || c))
export const TheFooter = () => import('../../src/components/TheFooter/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c))
export const TheHeader = () => import('../../src/components/TheHeader/TheHeader.vue' /* webpackChunkName: "components/the-header" */).then(c => wrapFunctional(c.default || c))
export const TheHeaderAdfox = () => import('../../src/components/TheHeaderAdfox/TheHeaderAdfox.vue' /* webpackChunkName: "components/the-header-adfox" */).then(c => wrapFunctional(c.default || c))
export const TheHeaderPartner = () => import('../../src/components/TheHeaderPartner/TheHeaderPartner.vue' /* webpackChunkName: "components/the-header-partner" */).then(c => wrapFunctional(c.default || c))
export const TheMenu = () => import('../../src/components/TheMenu/TheMenu.vue' /* webpackChunkName: "components/the-menu" */).then(c => wrapFunctional(c.default || c))
export const TheMenuMobile = () => import('../../src/components/TheMenuMobile/TheMenuMobile.vue' /* webpackChunkName: "components/the-menu-mobile" */).then(c => wrapFunctional(c.default || c))
export const TheMenuProfile = () => import('../../src/components/TheMenuProfile/TheMenuProfile.vue' /* webpackChunkName: "components/the-menu-profile" */).then(c => wrapFunctional(c.default || c))
export const ThePaywall = () => import('../../src/components/ThePaywall/ThePaywall.vue' /* webpackChunkName: "components/the-paywall" */).then(c => wrapFunctional(c.default || c))
export const ThePlayer = () => import('../../src/components/ThePlayer/ThePlayer.vue' /* webpackChunkName: "components/the-player" */).then(c => wrapFunctional(c.default || c))
export const TheSearch = () => import('../../src/components/TheSearch/TheSearch.vue' /* webpackChunkName: "components/the-search" */).then(c => wrapFunctional(c.default || c))
export const TheSearchMobile = () => import('../../src/components/TheSearchMobile/TheSearchMobile.vue' /* webpackChunkName: "components/the-search-mobile" */).then(c => wrapFunctional(c.default || c))
export const TheWaterfall = () => import('../../src/components/TheWaterfall/TheWaterfall.vue' /* webpackChunkName: "components/the-waterfall" */).then(c => wrapFunctional(c.default || c))
export const TheWaterfallAdfox = () => import('../../src/components/TheWaterfallAdfox/TheWaterfallAdfox.vue' /* webpackChunkName: "components/the-waterfall-adfox" */).then(c => wrapFunctional(c.default || c))
export const TheWaterfallFeed = () => import('../../src/components/TheWaterfallFeed/TheWaterfallFeed.vue' /* webpackChunkName: "components/the-waterfall-feed" */).then(c => wrapFunctional(c.default || c))
export const TheWaterfallItem = () => import('../../src/components/TheWaterfallItem/TheWaterfallItem.vue' /* webpackChunkName: "components/the-waterfall-item" */).then(c => wrapFunctional(c.default || c))
export const TheWaterfallItemAdfox = () => import('../../src/components/TheWaterfallItemAdfox/TheWaterfallItemAdfox.vue' /* webpackChunkName: "components/the-waterfall-item-adfox" */).then(c => wrapFunctional(c.default || c))
export const TheWaterfallMobile = () => import('../../src/components/TheWaterfallMobile/TheWaterfallMobile.vue' /* webpackChunkName: "components/the-waterfall-mobile" */).then(c => wrapFunctional(c.default || c))
export const Tooltip = () => import('../../src/components/Tooltip/Tooltip.vue' /* webpackChunkName: "components/tooltip" */).then(c => wrapFunctional(c.default || c))
export const TooltipBody = () => import('../../src/components/TooltipBody/TooltipBody.vue' /* webpackChunkName: "components/tooltip-body" */).then(c => wrapFunctional(c.default || c))
export const TooltipMobile = () => import('../../src/components/TooltipMobile/TooltipMobile.vue' /* webpackChunkName: "components/tooltip-mobile" */).then(c => wrapFunctional(c.default || c))
export const TransitionExpand = () => import('../../src/components/TransitionExpand/TransitionExpand.vue' /* webpackChunkName: "components/transition-expand" */).then(c => wrapFunctional(c.default || c))
export const UserCards = () => import('../../src/components/UserCards/UserCards.vue' /* webpackChunkName: "components/user-cards" */).then(c => wrapFunctional(c.default || c))
export const UserPlan = () => import('../../src/components/UserPlan/UserPlan.vue' /* webpackChunkName: "components/user-plan" */).then(c => wrapFunctional(c.default || c))
export const Zoomable = () => import('../../src/components/Zoomable/Zoomable.vue' /* webpackChunkName: "components/zoomable" */).then(c => wrapFunctional(c.default || c))
export const RbcAbout = () => import('../../src/components/RatingBestCities/RbcAbout/RbcAbout.vue' /* webpackChunkName: "components/rbc-about" */).then(c => wrapFunctional(c.default || c))
export const RbcCard = () => import('../../src/components/RatingBestCities/RbcCard/RbcCard.vue' /* webpackChunkName: "components/rbc-card" */).then(c => wrapFunctional(c.default || c))
export const RbcFilters = () => import('../../src/components/RatingBestCities/RbcFilters/RbcFilters.vue' /* webpackChunkName: "components/rbc-filters" */).then(c => wrapFunctional(c.default || c))
export const RbcHeader = () => import('../../src/components/RatingBestCities/RbcHeader/RbcHeader.vue' /* webpackChunkName: "components/rbc-header" */).then(c => wrapFunctional(c.default || c))
export const RbeCard = () => import('../../src/components/RatingBestEmployers/RbeCard/RbeCard.vue' /* webpackChunkName: "components/rbe-card" */).then(c => wrapFunctional(c.default || c))
export const RbeCompany = () => import('../../src/components/RatingBestEmployers/RbeCompany/RbeCompany.vue' /* webpackChunkName: "components/rbe-company" */).then(c => wrapFunctional(c.default || c))
export const RbeFilters = () => import('../../src/components/RatingBestEmployers/RbeFilters/RbeFilters.vue' /* webpackChunkName: "components/rbe-filters" */).then(c => wrapFunctional(c.default || c))
export const RbeHeader = () => import('../../src/components/RatingBestEmployers/RbeHeader/RbeHeader.vue' /* webpackChunkName: "components/rbe-header" */).then(c => wrapFunctional(c.default || c))
export const RbeMedals = () => import('../../src/components/RatingBestEmployers/RbeMedals/RbeMedals.vue' /* webpackChunkName: "components/rbe-medals" */).then(c => wrapFunctional(c.default || c))
export const RbeMenu = () => import('../../src/components/RatingBestEmployers/RbeMenu/RbeMenu.vue' /* webpackChunkName: "components/rbe-menu" */).then(c => wrapFunctional(c.default || c))
export const RbeSearch = () => import('../../src/components/RatingBestEmployers/RbeSearch/RbeSearch.vue' /* webpackChunkName: "components/rbe-search" */).then(c => wrapFunctional(c.default || c))
export const RbeTabs = () => import('../../src/components/RatingBestEmployers/RbeTabs/RbeTabs.vue' /* webpackChunkName: "components/rbe-tabs" */).then(c => wrapFunctional(c.default || c))
export const RbeTitle = () => import('../../src/components/RatingBestEmployers/RbeTitle/RbeTitle.vue' /* webpackChunkName: "components/rbe-title" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
